import useClientRouter from "./useClientRouter";
import { IS_SSR } from "@/constants/common";

const useLocation = () => {
  const router = useClientRouter();

  return IS_SSR
    ? {
        pathname: router.asPath,
        search: "not implemented",
      }
    : window.location;
};

export default useLocation;
