import { crudFetcherCommon, crudFetcherNextApi } from "services/crud";
import apiPublic, { nextApiPublic } from "./constants/apiPublic";
import { defaultCurrency } from "@/hooks/useCurrency";
import { IApiBreadcrumbsList } from "@/types/interfaces/categories";
import { IApiLanguage } from "@/types/interfaces/primitives";

const getDictionary = (lang: string, target?: string): Promise<{ [key: string]: string }> => {
  return crudFetcherNextApi.get({
    url: nextApiPublic.dictionary(lang, target),
  });
};


const getLanguages = (): Promise<IApiLanguage[]> => {
  return crudFetcherCommon.get({
    url: apiPublic.languages(),
  });
};

const currencies = () => {
  // mock for MVP v.1
  return Promise.resolve([defaultCurrency]);

  return crudFetcherCommon.get({
    url: apiPublic.currencies(),
  });
};

const categoryBreadcrumbs = (id: number): Promise<IApiBreadcrumbsList | null> => {
  return crudFetcherCommon.get({
    url: apiPublic.breadcrumbs(id),
  });
};

const storeTags = () => {
  return crudFetcherCommon.get({
    url: apiPublic.storesTags(),
  });
};

const countries = () => {
  return crudFetcherCommon.get({
    url: apiPublic.countries(),
  });
};

const countryProvinces = (countryCode: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.countryProvinces(countryCode),
  });
};

const countryCities = (countryCode: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.countryCities(countryCode),
  });
};

const products = (locale: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.products(locale),
  });
};

const product = (locale: string, productSlug: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.product(locale, productSlug),
  });
};

const productVariant = (locale: string, productSlug: string, variantId: number | string) => {
  return crudFetcherCommon.get({
    url: apiPublic.productVariant(locale, productSlug, variantId),
  });
};

const relatedProducts = (productId: any, locale: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.relatedProducts(productId, locale),
  });
};

// Only regular properties
const getProperty = (locale: string, propertyId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.property(locale, propertyId),
  });
};

const categoryProperties = (locale: string, categoryId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.propertiesOfCategory(locale, categoryId),
  });
};

const storesDeliveryOptions = () => {
  return crudFetcherCommon.get({
    url: apiPublic.storesDeliveryOptions(),
  });
};

const stores = (locale: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.stores(locale),
  });
};

const store = (storeId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.store(storeId),
  });
};

const feturedStores = () => {
  return crudFetcherCommon.get({
    url: apiPublic.feturedStores(),
  });
};

const newStores = () => {
  return crudFetcherCommon.get({
    url: apiPublic.newStores(),
  });
};

const geocodeAddressSearch = (address: string) => {
  return crudFetcherNextApi.get({
    url: `/api/geocode/address/?q=${address}`,
  });
};

const geocodeAutocomplete = (address: string) => {
  return crudFetcherNextApi.get({
    url: `/api/geocode/autocomplete/?input=${address}&language=ua`,
  });
};

const getProductVariantProperties = (locale: string, id: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.productVariantProperties(locale, id),
  });
};

// SUPPORT

const sendQuestionToSupport = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiPublic.support(),
    requestData,
  });
};

const subscribeToRecommendations = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiPublic.subscribe(),
    requestData,
  });
}

const confirmSubscription = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiPublic.confirmSubscription(),
    requestData,
  });
}

const confirmUnsubscription = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiPublic.confirmUnsubscription(),
    requestData,
  });
}

const getProductsByIds = (locale: string, requestData: any) => {
  return crudFetcherCommon.post({
    url: apiPublic.productsByIds(locale),
    requestData,
  });
}

const publicRequests = {
  getDictionary,
  getLanguages,
  currencies,
  categoryBreadcrumbs,
  storeTags,
  countries,
  countryProvinces,
  countryCities,
  products,
  product,
  productVariant,
  relatedProducts,
  getProperty,
  categoryProperties,
  storesDeliveryOptions,
  stores,
  store,
  feturedStores,
  newStores,
  geocodeAddressSearch,
  geocodeAutocomplete,
  getProductVariantProperties,
  sendQuestionToSupport,
  subscribeToRecommendations,
  confirmSubscription,
  confirmUnsubscription,
  getProductsByIds,
};

export default publicRequests;
