"use client";
import { useCallback, useEffect, useMemo, useState } from "react";
import publicRequests from "@/modules/public/requests";
import { IApiCurrency } from "@/types/interfaces/primitives";

const KEY_NAME_CURRENCY = "currency";

// mock for MVP v.1
export const mockCurrencyCode = "UAH";

export const defaultCurrency = {
  code: mockCurrencyCode,
  name: "Гривня",
  rate: "1",
  symbol: "₴",
  is_active: true,
};

const useCurrency = () => {
  const [value, setValue] = useState(defaultCurrency.code);
  const [list, setList] = useState([defaultCurrency]);

  useEffect(() => {
    publicRequests
      .currencies()
      .then((response) =>
        setList(
          response.map((item: IApiCurrency) => ({
            value: item.code,
            label: item.name,
            rate: item.rate,
            symbol: item.symbol,
          }))
        )
      )
      .catch((err) => console.error("Error retrieve currencies data", err));
  }, []);

  const priceByRate = useCallback(
    (price: any) => {
      return price;

      let rate = list.find((i: any) => i.value === value)?.rate || defaultCurrency.rate;
      // rate = parseFloat(rate);

      let val;
      if (typeof price === "string") {
        val = parseFloat(price);
      } else {
        val = price;
      }

      // return val * rate;
    },
    [list, value]
  );

  const data = useMemo(
    () => ({
      value: value || defaultCurrency.code,
      priceByRate,
      symbol: list.find((i: any) => i.value === value)?.symbol || defaultCurrency.symbol,
      list,
      onChange: (val: any) => {
        setValue(val);
        global?.localStorage?.setItem(KEY_NAME_CURRENCY, val);
      },
    }),
    [value, priceByRate, list]
  );

  // init
  useEffect(() => {
    const currencyFromStorage = global?.localStorage?.getItem(KEY_NAME_CURRENCY);
    if (currencyFromStorage && list.length && list.find((c) => c.code === currencyFromStorage)) {
      setValue(currencyFromStorage);
    }
  }, [list]);

  return data;
};

export default useCurrency;
