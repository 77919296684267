interface ICategory {
  parent: number | null;
  ordering: number;
}

export const sortCategories = (a: ICategory, b: ICategory) => {
  if (a.parent === null && b.parent === null) {
    if (a.ordering === b.ordering) {
      return 0;
    }

    return a.ordering > b.ordering ? 1 : -1;
  }

  if (a.parent === null) {
    return -1;
  }

  if (b.parent === null) {
    return 1;
  }

  if (a.ordering === b.ordering) {
    return 0;
  }

  return a.ordering > b.ordering ? 1 : -1;
};

export const sortById = (a: {id: number}, b: {id: number}) => a.id < b.id ? -1 : 1;

export const sortByOrdering = (a: {ordering: number}, b: {ordering: number}) => {
  if (a.ordering === b.ordering) {
    return 0;
  }

  return a.ordering < b.ordering ? -1 : 1;
}