import { useCallback, useEffect, useState } from "react";

const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max);

const getVisualViewPortValues = ({ clampOffsets = false, resizeDimensions = false }) => {
  let {
    width,
    height,
    widthOrig = null,
    heightOrig = null,
    scale,
    offsetTop,
    offsetLeft,
    offsetTopOrig = null,
    offsetLeftOrig = null,
    pageLeft,
    pageTop,
    pageLeftOrig = null,
    pageTopOrig = null,
  } = window.visualViewport as unknown as {
    width: number;
    height: number;
    widthOrig: number;
    heightOrig: number;
    scale: number;
    offsetTop: number;
    offsetLeft: number;
    offsetTopOrig: number;
    offsetLeftOrig: number;
    pageLeft: number;
    pageTop: number;
    pageLeftOrig: number;
    pageTopOrig: number;
  };

  // Adjust values by clamping
  if (clampOffsets) {
    // Cache original values
    pageTopOrig = pageTop;
    pageLeftOrig = pageLeft;
    offsetTopOrig = offsetTop;
    offsetLeftOrig = offsetLeft;

    // Offsets measured against the page cannot go negative, nor exceed the max scroll offset
    // @note: Values below only used for absolute positioning
    pageTop = clamp(pageTop, 0, document.body.offsetHeight - height);
    pageLeft = clamp(pageLeft, 0, document.body.offsetWidth - width);

    // Offsets measured against the Layout Viewport cannot go negative, nor exceed the max offset within the Layout Viewport
    // @note: Values below only used for fixed positioning
    const layoutViewportHeight = window.innerHeight * scale; // @TODO: This only is true when window.innerHeight resizes. Which is not always the case when pinch-zooming (or which happens too late as UA UI shows/hides)
    const layoutViewportWidth = window.innerWidth * scale; // @TODO: This only is true when window.innerHeight resizes. Which is not always the case when pinch-zooming (or which happens too late as UA UI shows/hides)
    offsetTop = clamp(offsetTop, 0, layoutViewportHeight - height);
    offsetLeft = clamp(offsetLeft, 0, layoutViewportWidth - width);
  }

  // Adjust values by resizing height/width
  // Only needed for browsers that expose overscrolling through scrollX/scrollY
  if (resizeDimensions) {
    widthOrig = width;
    heightOrig = height;
    pageTopOrig = pageTop;
    pageLeftOrig = pageLeft;
    offsetTopOrig = offsetTop;
    offsetLeftOrig = offsetLeft;

    // Mobile Safari: Fixed Viewport does overscroll. Max value can overshoot 0 (inverse of overScrollY) and min value can exceed inverted max scroll distance
    // Desktop Safari: Fixed Viewport does not overscroll. Max value is 0, Min value is inverted max scroll distance.
    // We need a way to distinguish between both, because they need a different fix
    // @TODO: No longer rely on UA sniffing here, and find a way to detect wether the Fixed Viewport can overscroll or not …
    const isMobileSafari = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
    const isDesktopSafari = !isMobileSafari;

    // Overscrolling at the right edge
    if (width + pageLeft > document.body.offsetWidth) {
      width = document.body.offsetWidth - pageLeft;

      // @note: Value below only used for fixed positioning
      if (isMobileSafari) {
        if (scale == 1) offsetLeft -= widthOrig - width;
        if (offsetLeft < 0) offsetLeft = -offsetLeft;
      } else {
        offsetLeft += widthOrig - width; // Add overscroll value to the offset
      }
    }

    // Overscrolling at the bottom edge
    if (height + pageTop > document.body.offsetHeight) {
      height = document.body.offsetHeight - pageTop;

      // @note: Value below only used for fixed positioning
      if (isMobileSafari) {
        if (scale == 1) offsetTop -= heightOrig - height;
        if (offsetTop < 0) offsetTop = -offsetTop;
      } else {
        offsetTop += heightOrig - height; // Add overscroll value to the offset
      }
    }

    // Overscrolling at the left edge
    // @note: Might be tricky to achieve as you might trigger back navigation
    if (pageLeft < 0) {
      width += pageLeft;

      // @note: Value below only used for abs position
      pageLeft = 0;

      // @note: Value below only used for fixed positioning
      offsetLeft = Math.abs(offsetLeft);
    }

    // Overscrolling at the top edge
    // @note: Might be tricky to achieve as you might trigger pull-to-refresh
    if (pageTop < 0) {
      height += pageTop;

      // @note: Value below only used for abs positioning
      pageTop = 0;

      // @note: Value below only used for fixed positioning
      offsetTop = Math.abs(offsetTop);
    }
  }

  return Object.fromEntries(
    Object.entries({
      width,
      widthOrig,
      height,
      heightOrig,
      scale,
      offsetTop,
      offsetTopOrig,
      offsetLeft,
      offsetLeftOrig,
      pageLeft,
      pageLeftOrig,
      pageTop,
      pageTopOrig,
    }).filter(([k, v]) => v !== null)
  );
};

export type ViewportType = {
  width?: number;
  height?: number;
  scale?: number;
  offsetTop?: number;
  offsetLeft?: number;
  pageLeft?: number;
  pageTop?: number;
};

const useViewport = (autoTick = 200, init = true) => {
  const [viewport, setViewport] = useState<ViewportType>({});

  const update = useCallback(() => {
    const values = getVisualViewPortValues({ clampOffsets: false, resizeDimensions: false });
    setViewport(values || {});
  }, []);

  useEffect(() => {
    if (init) {
      // Update on scroll/resize
      window.addEventListener("scroll", update, { passive: true });
      window.visualViewport?.addEventListener("scroll", update, { passive: true });
      window.visualViewport?.addEventListener("resize", update, { passive: true });

      // Make sure we have values on load
      setTimeout(update, 100);

      if (autoTick) {
        setInterval(update, autoTick);
      }
    }
  }, [autoTick, init, update]);

  return viewport;
};

export default useViewport;
