import { DataSWR } from "@/libs/swr";

export const runActionsSequence = async (
  action: (arg: any) => Promise<Response>,
  args: any[]
) => {
  for (const item of args) {
    await action(item);
  }
};

export const runSequence = async (actions: (() => Promise<Response>)[]) => {
  for (const item of actions) {
    await item();
  }
};

export function mockPromise<T>(data: T): Promise<T> {
  return new Promise((resolve) => resolve(data));
};

export const mockSWR = <T extends unknown>(data: T) => {
  return {
    data,
    error: false,
    isLoading: false,
    isValidating: false,
    mutate: () => {},
  } as DataSWR<T>;
};
