import { useCallback, useEffect, useMemo, useState } from "react";
import { hashObject } from "@/utils/data";

const useThrottle = (delay: number = 1000, initKey?: any) => {
  const cont = useMemo(
    (): any => ({
      timer: undefined,
      key: hashObject(initKey),
    }),
    [initKey]
  );

  const throttle = useCallback(
    (cb: () => void, key: any) => {
      const newKey = hashObject(key);

      cont.key = newKey;
      clearTimeout(cont.timer);
      cont.timer = setTimeout(cb, delay);
    },
    [cont, delay]
  );

  return throttle;
};

export default useThrottle;

export const useThrottleValue = (delay: number = 1000, init?: any) => {
  const [value, setValue] = useState(init);

  useEffect(() => {
    function set() {
      setValue(init);
    }
    const timer = setTimeout(set, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [delay, init]);

  return value;
};
